<span [ngClass]="{
    'regular': stylingClass === 'regular',
    'light': stylingClass === 'light'}"
>
  @if (amount) {
    @if (amount.total) {
      {{ amount.total | currency:'EUR':'symbol':'.0':'fr' }}
    } @else if (amount.min) {
      {{ amount.min | currency:'EUR':'symbol':'.0':'fr' }} (min.)
      {{ amount.max ? ' - ' + (amount.max | currency:'EUR':'symbol':'.0':'fr') + ' (max.)' : '' }}
    } @else if (amount.max) {
      {{ amount.max | currency:'EUR':'symbol':'.0':'fr' }} (max.)
    } @else {
      <ng-container [ngTemplateOutlet]="undetected"></ng-container>
    }
  } @else {
    <ng-container [ngTemplateOutlet]="undetected"></ng-container>
  }
</span>

<ng-template #undetected>
  <span class="undetected-message">{{ 'tenders.' + undetectedTranslationKey | translate }}</span>
</ng-template>
