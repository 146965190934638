import { Component, Input } from '@angular/core';
import { CurrencyPipe, NgClass, NgTemplateOutlet } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: 'app-tender-amount',
  standalone: true,
  imports: [
    CurrencyPipe,
    TranslateModule,
    NgClass,
    NgTemplateOutlet
  ],
  templateUrl: './tender-amount.component.html',
  styleUrl: './tender-amount.component.scss'
})
export class TenderAmountComponent {
  @Input() amount?: {min: number, max: number, total: number};
  @Input() undetectedTranslationKey = 'not-detected-amount';
  @Input() stylingClass : 'regular' | 'light' = 'regular';
}
